import Grid from "@mui/material/Grid2";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import clsx from "clsx";
import { store as dashboardStore } from "../../Stores/Dashboard";
import "./styles.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function MultiDashboardMenu({
	ds,
	onClick,
	selectedDashboardMenu,
	open,
	isActive,
}) {
	return (
		<Grid
			key={ds._id}
			container
			onClick={ds.disabled ? undefined : onClick}
			className={clsx({
				active: isActive,
				inactive: !isActive,
				disabled: ds.disabled,
				enabled: !ds.disabled,
			})}
			sx={{
				my: 1,
				mx: 2,
				py: 0.5,
			}}
		>
			<Grid display="flex">{ds.displayName}</Grid>
			<Grid display="flex">
				<ArrowDropDownIcon
					sx={{
						transform:
							open && selectedDashboardMenu._id === ds._id
								? "scaleY(-1)"
								: "scaleY(1)",
					}}
				/>
			</Grid>
		</Grid>
	);
}

function SingleDashboardMenu({ onClick, isActive, ds }) {
	return (
		<Grid
			key={ds._id}
			container
			onClick={ds.disabled ? undefined : onClick}
			className={clsx({
				active: isActive,
				inactive: !isActive,
				disabled: ds.disabled,
				enabled: !ds.disabled,
			})}
			sx={{
				my: 1,
				mx: 2,
				py: 0.5,
			}}
		>
			<Grid display="flex">{ds.displayName}</Grid>
		</Grid>
	);
}

export default ({ selectedNamespace, selectedDashboard }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedDashboardMenu, setSelectedDashboardMenu] = useState(null);
	const navigate = useNavigate();

	const isActive = (dashboard) => {
		return dashboard.items.includes(selectedDashboard);
	};

	const allAvailableDashboards =
		dashboardStore.getDashboardMenusByNamespace(selectedNamespace);

	const displayDashboards =
		allAvailableDashboards.length > 1 ? allAvailableDashboards : [];

	return (
		<>
			<Grid container justify="space-around">
				{displayDashboards.map((ds) => (
					<Grid key={ds._id}>
						{ds.items.length > 1 ? (
							<MultiDashboardMenu
								ds={ds}
								open={Boolean(anchorEl)}
								onClick={(event) => {
									setAnchorEl(event.currentTarget);
									setSelectedDashboardMenu(ds);
								}}
								selectedDashboardMenu={selectedDashboardMenu}
								isActive={isActive(ds)}
							/>
						) : (
							<SingleDashboardMenu
								ds={ds}
								onClick={(event) => {
									navigate(
										`/dashboard/${ds.items[0].slug}?n=${selectedNamespace}`,
									);
								}}
								isActive={isActive(ds)}
							/>
						)}
					</Grid>
				))}
			</Grid>
			<Menu
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				sx={{
					mt: "1em",
				}}
				keepMounted
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				open={Boolean(anchorEl)}
				onClose={() => {
					setAnchorEl(null);
				}}
			>
				{selectedDashboardMenu?.items.map((d) => (
					<MenuItem
						key={d.slug}
						sx={{
							cursor: "pointer",
						}}
						onClick={() => {
							navigate(`/dashboard/${d.slug}?n=${selectedNamespace}`);
							setAnchorEl(null);
							setSelectedDashboardMenu(null);
						}}
					>
						{d.displayName}
					</MenuItem>
				))}
			</Menu>
		</>
	);
};
