import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useState } from "react";
import { fetchServiceDeskRequestForm } from "../../Clients/NodeServer";
import { handleGetAccessToken } from "../../Helpers/auth";
import JiraProFormParser from "./JiraProFormParser";

export default () => {
	const [isFetchingRequestForm, setIsFetchingRequestForm] = useState(false);
	const [hasFetchedRequestForm, setHasFetchedRequestForm] = useState(false);
	const [requestFields, setRequestFields] = useState([]);
	const [formFields, setFormFields] = useState(new JiraProFormParser());
	const [error, setError] = useState(null);

	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

	const fetchRequestFormCached = useCallback(
		(serviceDeskId, requestId) => {
			return handleGetAccessToken(getAccessTokenSilently, loginWithRedirect)
				.then((token) => {
					setIsFetchingRequestForm(true);
					return fetchServiceDeskRequestForm(token, serviceDeskId, requestId);
				})
				.then((response) => {
					setRequestFields(response.data.requestFields);
					setFormFields(
						new JiraProFormParser().parse(response.data.formFields),
					);
					setHasFetchedRequestForm(true);
				})
				.catch((e) => {
					setHasFetchedRequestForm(true); // for now so we don't try and refetch
					setError(e);
					console.error(e);
				})
				.finally(() => setIsFetchingRequestForm(false));
		},
		[getAccessTokenSilently, loginWithRedirect],
	);

	return {
		isFetchingRequestForm,
		hasFetchedRequestForm,
		requestFields,
		formFields,
		error,
		fetchRequestForm: fetchRequestFormCached,
	};
};
