import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useState } from "react";
import { uploadAttachment } from "../../Clients/NodeServer";
import { handleGetAccessToken } from "../../Helpers/auth";

export default () => {
	const [isUploadingAttachment, setIsUploadingAttachment] = useState(false);
	const [hasUploadedAttachment, setHasUploadedAttachment] = useState(false);
	const [jiraId, setJiraId] = useState(null);
	const [file, setFile] = useState(null);
	const [error, setError] = useState(null);

	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

	const uploadAttachmentCached = useCallback(
		(serviceDeskId, file) => {
			return handleGetAccessToken(getAccessTokenSilently, loginWithRedirect)
				.then((token) => {
					setIsUploadingAttachment(true);
					return uploadAttachment(token, serviceDeskId, file);
				})
				.then((response) => {
					setJiraId(
						response.data.temporaryAttachments.at(0).temporaryAttachmentId,
					);
					setHasUploadedAttachment(true);
					setFile(file);
				})
				.catch((e) => {
					setHasUploadedAttachment(true); // for now so we don't try and refetch
					setError(e);
					console.error(e);
				})
				.finally(() => setIsUploadingAttachment(false));
		},
		[getAccessTokenSilently, loginWithRedirect],
	);

	const reset = useCallback(() => {
		setIsUploadingAttachment(false);
		setHasUploadedAttachment(false);
		setJiraId(null);
		setFile(null);
		setError(null);
	}, []);

	return {
		isUploadingAttachment,
		hasUploadedAttachment,
		jiraId,
		reset,
		file,
		error,
		uploadAttachment: uploadAttachmentCached,
	};
};
