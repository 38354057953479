import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid2";
import { useCallback, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import PageHeader from "../../Components/PageHeader";
import SpHeader from "../../Components/SpHeader";
import useServiceDesks from "./useServiceDesks";

const descriptions = {
	5: "Documentation for SailPlan software and associated hardware",
	2: "Welcome! You can raise a request for Personnel Management using the options provided.",
	4: "Select one of the available options to submit a service request.",
};
export default () => {
	const {
		response: serviceDesks,
		isFetchingServiceDesks,
		hasFetchedServiceDesks,
		fetchServiceDesks,
	} = useServiceDesks();
	const [searchParams] = useSearchParams();

	const selectedNamespace = searchParams.get("n");

	useEffect(() => {
		if (!isFetchingServiceDesks && !hasFetchedServiceDesks) {
			fetchServiceDesks();
		}
	}, [fetchServiceDesks, hasFetchedServiceDesks, isFetchingServiceDesks]);

	const MyRequests = useCallback(
		() => (
			<Grid container spacing={16} sx={{ px: 16, py: 8 }}>
				<Grid size={{ xs: 4 }} container>
					<Card sx={{ width: "100%" }} variant="outlined">
						<CardContent>
							<Link to={"/helpcenter/requests"}>
								<SpHeader
									sx={{
										p: 0,
										display: "flex",
										height: "32px",
									}}
								>
									Existing Requests
								</SpHeader>
								<Box sx={{ color: "#000000" }}>
									View submitted support requests
								</Box>
							</Link>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		),
		[],
	);

	return (
		<>
			<PageHeader>Help Center</PageHeader>
			{isFetchingServiceDesks || !hasFetchedServiceDesks ? (
				<Box
					sx={{
						position: "absolute",
						top: "50vh",
						left: "50vw",
						transform: "translate(-50%, -50%)",
					}}
				>
					<BounceLoader color="#44ABDF" size={80} />
				</Box>
			) : serviceDesks.length ? (
				<>
					<MyRequests />
					<Divider sx={{ mx: 16 }} />
					<SpHeader sx={{ mx: 16, height: "1em", pt: 4 }}>
						Resouces / Support Requests
					</SpHeader>
					<Grid container spacing={16} sx={{ px: 16, pt: 4, pb: 8 }}>
						{serviceDesks.map((s) => (
							<Grid key={s.id} size={{ xs: 4 }} container>
								<Card sx={{ width: "100%" }} variant="outlined">
									<CardContent>
										<Link
											to={`/helpcenter/desks/${s.id}?n=${selectedNamespace}`}
											state={{ serviceDesk: s }}
										>
											<SpHeader
												sx={{
													p: 0,
													display: "flex",
													height: "32px",
												}}
											>
												{s.projectName}
											</SpHeader>
											<Box sx={{ color: "#000000" }}>
												{descriptions[s.id] || ""}
											</Box>
										</Link>
									</CardContent>
								</Card>
							</Grid>
						))}
					</Grid>
				</>
			) : (
				<MyRequests />
			)}
		</>
	);
};
