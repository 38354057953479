import { useAuth0 } from "@auth0/auth0-react";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { useEffect, useState, useSyncExternalStore } from "react";
import { handleGetAccessToken } from "../../Helpers/auth";
import {
	actions as exportActions,
	store as exportStore,
} from "../../Stores/Export";
import useFetchExportJob from "./useFetchExportJob";

function callAgainInXSeconds(fn, seconds) {
	setTimeout(fn, seconds * 1000);
}

export default function ExportTracker() {
	const [showSnackbar, setShowSnackbar] = useState(false);
	const {
		hasFetchedExport,
		response: exportResponse,
		fetchExport,
		isFetchingExport,
	} = useFetchExportJob();

	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

	const { activeExports } = useSyncExternalStore(
		exportStore.subscribeToExports,
		exportStore.getExports,
	);

	const activeExport = activeExports.at(0)?._id;
	const activeExportState = exportResponse?.state;

	useEffect(() => {
		if (activeExport && !isFetchingExport) {
			callAgainInXSeconds(() => fetchExport(activeExport), 5);
		}
	}, [activeExport, fetchExport, isFetchingExport]);

	useEffect(() => {
		if (hasFetchedExport && activeExportState) {
			if (activeExportState === "DONE") {
				setShowSnackbar(true);
				// biome-ignore lint/correctness/noUnusedVariables: calling the constructor triggers the notification in the browser
				const notification = new Notification("Sailplan Data Export", {
					body: "Your data export is now ready for download.",
					icon: `${window.location.origin}/SailPlan-Logomark.png`,
				});
				exportActions.setIsExportReady(true);
				handleGetAccessToken(getAccessTokenSilently, loginWithRedirect).then(
					(token) => {
						exportActions.fetchInProgressExports(token);
					},
				);
			}
		}
	}, [
		hasFetchedExport,
		activeExportState,
		getAccessTokenSilently,
		loginWithRedirect,
	]);

	useEffect(() => {
		handleGetAccessToken(getAccessTokenSilently, loginWithRedirect).then(
			(token) => {
				exportActions.fetchInProgressExports(token);
			},
		);
	}, [getAccessTokenSilently, loginWithRedirect]);

	return (
		//TODO add close button and close when link is clicked
		<Snackbar
			anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
			open={showSnackbar}
		>
			<Alert
				severity="success"
				sx={{ width: "100%" }}
				onClose={() => setShowSnackbar(false)}
			>
				{"Export is ready."}{" "}
				<a
					download
					href={exportResponse?.links?.at(0)}
					onClick={() => setShowSnackbar(false)}
				>
					{"Click here to download"}
				</a>
			</Alert>
		</Snackbar>
	);
}
