import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useState } from "react";
import { getUsers } from "../../../Clients/NodeServer";
import { handleGetAccessToken } from "../../../Helpers/auth";

export default ({ pageSize }) => {
	const [isFetchingUsers, setIsFetchingUsers] = useState(false);
	const [hasFetchedUsers, setHasFetchedUsers] = useState(false);
	const [users, setUsers] = useState([]);
	const [error, setError] = useState(null);

	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

	const fetchUsersCached = useCallback(
		(namespace, query) => {
			return handleGetAccessToken(getAccessTokenSilently, loginWithRedirect)
				.then((token) => {
					setIsFetchingUsers(true);
					return getUsers(token, { pageSize, namespace, query });
				})
				.then((response) => {
					setUsers(response.data.users);
					setHasFetchedUsers(true);
				})
				.catch((e) => {
					setHasFetchedUsers(true); // for now so we don't try and refetch
					setError(e);
					console.error(e);
				})
				.finally(() => setIsFetchingUsers(false));
		},
		[getAccessTokenSilently, loginWithRedirect, pageSize],
	);

	return {
		isFetchingUsers,
		hasFetchedUsers,
		users,
		error,
		fetchUsers: fetchUsersCached,
	};
};
