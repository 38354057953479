import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default (props) => {
	return (
		<Dialog
			open={props.open}
			onClose={props.onCancel}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{`Delete ${props.name}?`}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					You will NOT be able to recover this user once it is deleted.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onCancel} variant="text">
					Cancel
				</Button>
				<Button onClick={props.onContinue} autoFocus variant="contained">
					Delete
				</Button>
			</DialogActions>
		</Dialog>
	);
};
