import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useState } from "react";
import { fetchServiceDesk } from "../../Clients/NodeServer";
import { handleGetAccessToken } from "../../Helpers/auth";

export default () => {
	const [isFetchingServiceDesk, setIsFetchingServiceDesk] = useState(false);
	const [hasFetchedServiceDesk, setHasFetchedServiceDesk] = useState(false);
	const [response, setResponse] = useState([]);
	const [error, setError] = useState(null);

	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

	const fetchServiceDeskCached = useCallback(
		(id) => {
			return handleGetAccessToken(getAccessTokenSilently, loginWithRedirect)
				.then((token) => {
					setIsFetchingServiceDesk(true);
					return fetchServiceDesk(token, id);
				})
				.then((response) => {
					setResponse(response.data.values);
					setHasFetchedServiceDesk(true);
				})
				.catch((e) => {
					setHasFetchedServiceDesk(true); // for now so we don't try and refetch
					setError(e);
					console.error(e);
				})
				.finally(() => setIsFetchingServiceDesk(false));
		},
		[getAccessTokenSilently, loginWithRedirect],
	);

	return {
		isFetchingServiceDesk,
		hasFetchedServiceDesk,
		response,
		error,
		fetchServiceDesk: fetchServiceDeskCached,
	};
};
