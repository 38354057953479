import { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

function sleep(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

function RefreshDialog(props) {
	const [count, setCount] = useState(props.countFrom);
	const [isCounting, setIsCounting] = useState(false);

	useEffect(() => {
		if (!isCounting) {
			return;
		}
		if (count === 0) {
			window.location.reload();
		} else {
			sleep(1000).then(() => {
				setCount(count - 1);
			});
		}
	}, [count, isCounting]);

	const resetCount = useCallback(() => {
		setIsCounting(false);
		setCount(props.countFrom);
	}, [props.countFrom]);

	const startCount = useCallback(() => {
		setCount(props.countFrom);
		setIsCounting(true);
	}, [props.countFrom]);

	const handleClose = () => {
		props.onClose();
		resetCount();
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (props.open) {
			startCount();
		} else {
			resetCount();
		}
	}, [props.open]);

	return (
		<Dialog open={props.open} keepMounted onClose={handleClose}>
			<DialogTitle>{"Session has expired."}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-slide-description">
					Refreshing page in {count} seconds...
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				<Button onClick={() => window.location.reload()}>Refresh</Button>
			</DialogActions>
		</Dialog>
	);
}

RefreshDialog.propTypes = {
	open: PropTypes.bool,
	countFrom: PropTypes.number,
	onClose: PropTypes.func,
};

export default RefreshDialog;
