import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useState } from "react";
import { fetchIssues } from "../../Clients/NodeServer";
import { handleGetAccessToken } from "../../Helpers/auth";

export default () => {
	const [isFetchingIssues, setIsFetchingIssues] = useState(false);
	const [hasFetchedIssues, setHasFetchedIssues] = useState(false);
	const [issues, setIssues] = useState([]);
	const [error, setError] = useState(null);

	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

	const fetchIssuesCached = useCallback(() => {
		return handleGetAccessToken(getAccessTokenSilently, loginWithRedirect)
			.then((token) => {
				setIsFetchingIssues(true);
				return fetchIssues(token);
			})
			.then((response) => {
				setIssues(response.data.values);
				setHasFetchedIssues(true);
			})
			.catch((e) => {
				setHasFetchedIssues(true); // for now so we don't try and refetch
				setError(e);
				console.error(e);
			})
			.finally(() => setIsFetchingIssues(false));
	});

	return {
		isFetchingIssues,
		hasFetchedIssues,
		issues,
		error,
		fetchIssues: fetchIssuesCached,
	};
};
