import LoadingButton from "@mui/lab/LoadingButton";
import { Stack } from "@mui/material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import Typeography from "@mui/material/Typography";
import { get, set } from "lodash";
import { omit } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import MultiAttach from "../../Components/MultiAttach";
import PageHeader from "../../Components/PageHeader";
import useServiceDeskRequestForm from "../../Hooks/useServiceDeskRequestForm";
import DatePicker from "./DatePicker";
import RichTextArea from "./RichTextArea";
import Select from "./Select";
import SelectUser from "./SelectUser";
import TextArea from "./TextArea";
import TextField from "./TextField";
import useServiceDeskAttach from "./useServiceDeskAttach";
import useServiceDeskPost from "./useServiceDeskPost";
import useServiceDeskRequest from "./useServiceDeskRequest";

function renderField(schema, initSetFormState, getFormState, serviceDeskId) {
	switch (schema.jiraSchema.custom ?? schema.jiraSchema.type) {
		case "string":
		case "com.atlassian.jira.plugin.system.customfieldtypes:textfield":
			if (schema.fieldId === "description") {
				return (
					<TextArea
						key={schema.fieldId}
						{...schema}
						setState={initSetFormState(schema.fieldId)}
						state={getFormState(schema.fieldId)}
					/>
				);
			}
			return (
				<TextField
					key={schema.fieldId}
					{...schema}
					setState={initSetFormState(schema.fieldId)}
					state={getFormState(schema.fieldId)}
				/>
			);
		case "textarea":
		case "com.atlassian.jira.plugin.system.customfieldtypes:textarea":
			return (
				<TextArea
					key={schema.fieldId}
					{...schema}
					setState={initSetFormState(schema.fieldId)}
					state={getFormState(schema.fieldId)}
				/>
			);
		case "select":
		case "com.atlassian.jira.plugin.system.customfieldtypes:select":
			return (
				<Select
					key={schema.fieldId}
					{...schema}
					setState={initSetFormState(schema.fieldId)}
					state={getFormState(schema.fieldId)}
				/>
			);
		case "date":
		case "com.atlassian.jira.plugin.system.customfieldtypes:datepicker":
			return (
				<DatePicker
					key={schema.fieldId}
					{...schema}
					setState={initSetFormState(schema.fieldId)}
					state={getFormState(schema.fieldId)}
				/>
			);
		case "com.atlassian.jira.plugin.system.customfieldtypes:multiuserpicker":
			return (
				<SelectUser
					key={schema.fieldId}
					{...schema}
					setState={initSetFormState(schema.fieldId)}
					state={getFormState(schema.fieldId)}
				/>
			);
		case "array":
			if (schema.jiraSchema.items === "attachment") {
				return (
					<MultiAttach
						key={schema.fieldId}
						{...schema}
						serviceDeskId={serviceDeskId}
						setState={initSetFormState(`attachments.${schema.fieldId}`)}
						state={getFormState(schema.fieldId)}
					/>
				);
			}
			break;
		case "richtext": // only for legacy forms
			return (
				<RichTextArea
					key={schema.fieldId}
					{...schema}
					setState={initSetFormState(schema.fieldId)}
					state={getFormState(schema.fieldId)}
				/>
			);
		case "paragraph":
			return <Typeography key={schema.fieldId}>{schema.text}</Typeography>;
		default:
			console.error("Unknown schema type", schema);
			return <></>;
	}
}

function getFieldId(fieldId, jiraSchemaItems) {
	if (jiraSchemaItems === "attachment") {
		return `attachments.${fieldId}`;
	}
	return fieldId;
}

function getMissingFields(requestForm, form) {
	const missingFields = [];
	for (const s of requestForm) {
		if (s.required && !get(form, getFieldId(s.fieldId, s.jiraSchema.items))) {
			missingFields.push(s.name);
		}
	}
	return missingFields;
}

export default () => {
	const { state } = useLocation();
	const navigate = useNavigate();
	const { requestId, serviceDeskId } = useParams();
	const [requestFieldsInput, setRequestFieldsInput] = useState({});
	const [formFieldsInput, setFormFieldsInput] = useState({});
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");

	const {
		serviceDeskRequest: fetchedServiceDeskRequest,
		isFetchingServiceDeskRequest,
		hasFetchedServiceDeskRequest,
		fetchServiceDeskRequest,
	} = useServiceDeskRequest();

	const {
		requestFields,
		formFields,
		isFetchingRequestForm,
		hasFetchedRequestForm,
		fetchRequestForm,
		error: requestFormError,
	} = useServiceDeskRequestForm();

	const {
		isPostingServiceDeskRequest,
		hasPostedServiceDeskRequest,
		response,
		error: formSubmitError,
		postServiceDeskRequest,
	} = useServiceDeskPost();

	const {
		isAttachingToIssue,
		hasAttachedToIssue,
		response: attachmentResponse,
		error: attachmentError,
		attachToIssue,
	} = useServiceDeskAttach();

	useEffect(() => {
		if (
			!state?.serviceDeskRequest &&
			!fetchedServiceDeskRequest &&
			!isFetchingServiceDeskRequest &&
			!hasFetchedServiceDeskRequest
		) {
			fetchServiceDeskRequest(serviceDeskId, requestId);
		}
	}, [
		fetchServiceDeskRequest,
		isFetchingServiceDeskRequest,
		hasFetchedServiceDeskRequest,
		fetchedServiceDeskRequest,
		state,
		requestId,
		serviceDeskId,
	]);

	useEffect(() => {
		if (!isFetchingRequestForm && !hasFetchedRequestForm) {
			fetchRequestForm(serviceDeskId, requestId);
		}
	}, [
		isFetchingRequestForm,
		hasFetchedRequestForm,
		fetchRequestForm,
		requestId,
		serviceDeskId,
	]);

	useEffect(() => {
		if (attachmentError) {
			setShowSnackbar(true);
			setSnackbarMessage(
				"There was an issue with one or more of you attachments. Please try again later.",
			);
		}
	}, [attachmentError]);

	useEffect(() => {
		if (formSubmitError) {
			setShowSnackbar(true);
			setSnackbarMessage("Something has gone wrong. Please try again later.");
		}
	}, [formSubmitError]);

	const serviceDeskRequest =
		state?.serviceDeskRequest || fetchedServiceDeskRequest;

	const isLoading =
		isFetchingServiceDeskRequest ||
		!serviceDeskRequest ||
		isFetchingRequestForm ||
		!hasFetchedRequestForm;

	const isSubmitting = isPostingServiceDeskRequest || isAttachingToIssue;

	const initSetRequestFieldInputState = useCallback((propName) => {
		return (value) => {
			setRequestFieldsInput((prev) => {
				const next = { ...prev };
				set(next, propName, value);
				return next;
			});
		};
	}, []);

	const initSetFormFieldInputState = useCallback(
		(propName) => {
			return (value) => {
				setFormFieldsInput((prev) => {
					const next = { ...prev };
					formFields.setValue(next, propName, value);
					return next;
				});
			};
		},
		[formFields],
	);

	const getRequestFieldInputState = useCallback(
		(propName) => {
			return requestFieldsInput[propName];
		},
		[requestFieldsInput],
	);

	const getFormFieldState = useCallback(
		(propName) => {
			return formFields.getValue(formFieldsInput, propName);
		},
		[formFieldsInput, formFields],
	);
	const onSubmit = useCallback(() => {
		const missingFields = [
			...getMissingFields(requestFields, requestFieldsInput),
			...getMissingFields(formFields.requestFields, formFieldsInput),
		];

		if (missingFields.length > 1) {
			setSnackbarMessage("Please fill in all fields marked with *");
			setShowSnackbar(true);
		} else if (missingFields.length === 1) {
			setSnackbarMessage(`Please fill in field "${missingFields[0]}"`);
			setShowSnackbar(true);
		} else {
			postServiceDeskRequest(
				serviceDeskId,
				requestId,
				omit(requestFieldsInput, "attachments"),
				omit(formFieldsInput, "attachments"),
			);
		}
	}, [
		postServiceDeskRequest,
		requestId,
		serviceDeskId,
		requestFieldsInput,
		requestFields,
		formFields.requestFields,
		formFieldsInput,
	]);

	useEffect(() => {
		if (!hasPostedServiceDeskRequest || formSubmitError) {
			return;
		}

		const issueKey = response.issueKey;
		const attachments = Object.values(requestFieldsInput.attachments ?? {})
			.flat()
			.concat(Object.values(formFieldsInput.attachments ?? {}).flat());
		if (attachments?.length) {
			attachToIssue(attachments, issueKey);
		} else {
			navigate(`/helpcenter/requests/${response.issueKey}`);
		}
	}, [
		attachToIssue,
		hasPostedServiceDeskRequest,
		response,
		requestFieldsInput,
		formFieldsInput,
		navigate,
		formSubmitError,
	]);

	useEffect(() => {
		if (!hasAttachedToIssue) {
			return;
		}
		navigate(`/helpcenter/requests/${response.issueKey}`);
	}, [hasAttachedToIssue, navigate, response]);

	return isLoading ? (
		<Box
			sx={{
				position: "absolute",
				top: "50vh",
				left: "50vw",
				transform: "translate(-50%, -50%)",
			}}
		>
			<BounceLoader color="#44ABDF" size={80} />
		</Box>
	) : (
		<>
			<PageHeader>{serviceDeskRequest.name}</PageHeader>
			{isFetchingRequestForm || !hasFetchedRequestForm ? (
				<Box
					sx={{
						position: "absolute",
						top: "50vh",
						left: "50vw",
						transform: "translate(-50%, -50%)",
					}}
				>
					<BounceLoader color="#44ABDF" size={80} />
				</Box>
			) : hasFetchedRequestForm ? (
				<Stack spacing={8} sx={{ px: 16, py: 8, width: "50%" }}>
					{requestFields.map((r) =>
						renderField(
							r,
							initSetRequestFieldInputState,
							getRequestFieldInputState,
							serviceDeskId,
						),
					)}
					{formFields.formElements.map((r) =>
						renderField(
							r,
							initSetFormFieldInputState,
							getFormFieldState,
							serviceDeskId,
						),
					)}
					<LoadingButton
						variant="contained"
						sx={{ width: "20%" }}
						onClick={onSubmit}
						loading={isSubmitting}
					>
						Submit
					</LoadingButton>
				</Stack>
			) : null}

			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				open={showSnackbar}
				autoHideDuration={6000}
				onClose={() => setShowSnackbar(false)}
			>
				<Alert severity={"error"} sx={{ width: "100%" }}>
					{snackbarMessage}
				</Alert>
			</Snackbar>
		</>
	);
};
