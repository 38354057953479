import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useState } from "react";
import { postComment } from "../../Clients/NodeServer";
import { handleGetAccessToken } from "../../Helpers/auth";

export default () => {
	const [isPostingComment, setIsPostingComment] = useState(false);
	const [hasPostedComment, setHasPostedComment] = useState(false);
	const [response, setResponse] = useState(null);
	const [error, setError] = useState(null);

	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

	const postCommentCached = useCallback(
		({ issueKey, comment, attachmentIds }) => {
			return handleGetAccessToken(getAccessTokenSilently, loginWithRedirect)
				.then((token) => {
					setError(null);
					setIsPostingComment(true);
					return postComment(token, {
						jiraKey: issueKey,
						attachmentIds,
						comment,
					});
				})
				.then((response) => {
					setResponse(response.data);
					setHasPostedComment(true);
				})
				.catch((e) => {
					setHasPostedComment(true); // for now so we don't try and refetch
					setError(e);
					console.error(e);
				})
				.finally(() => setIsPostingComment(false));
		},
		[getAccessTokenSilently, loginWithRedirect],
	);

	return {
		isPostingComment,
		hasPostedComment,
		response,
		error,
		postComment: postCommentCached,
	};
};
