import { LookerEmbedSDK } from "@looker/embed-sdk";
import { useEffect, useState, useRef } from "react";
import BounceLoader from "react-spinners/BounceLoader";
import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import "./styles.css";
import RefreshDialog from "../../../../Components/RefreshDialog";
import { handleGetAccessToken } from "../../../../Helpers/auth";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
	lookerAcquireSession,
	lookerGenerateTokens,
} from "../../../../Clients/NodeServer";

function LookerEmbed({ namespace, showLoading }) {
	const [isLoading, setIsLoading] = useState(false);
	const iframeContainerRef = useRef(null);
	const [showRefreshDialog, setShowRefreshDialog] = useState(false);
	const theme = useTheme();
	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

	//TODO move requests into nodeServer client
	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (!namespace) {
			return;
		}

		setIsLoading(true);

		async function start() {
			let api_token;
			let navigation_token;

			const aquireSession = async () => {
				const access_token = await handleGetAccessToken(
					getAccessTokenSilently,
					loginWithRedirect,
				);

				try {
					const resp = await lookerAcquireSession(access_token);
					api_token = resp.data.api_token;
					navigation_token = resp.data.navigation_token;
					return resp.data;
				} catch (error) {
					console.error(error);
					//TODO maybe we should do something better here?
					throw new Error("acquire embed session failed");
				}
			};

			const generateTokens = async () => {
				const access_token = await handleGetAccessToken(
					getAccessTokenSilently,
					loginWithRedirect,
				);
				try {
					const resp = await lookerGenerateTokens(
						access_token,
						api_token,
						navigation_token,
					);

					api_token = resp.data.api_token;
					navigation_token = resp.data.navigation_token;
					return resp.data;
				} catch (error) {
					if (error.status === 400) {
						return { session_reference_token_ttl: 0 };
					}
					//TODO maybe we should do something better here?
					throw new Error("generate tokens failed");
				}
			};

			LookerEmbedSDK.initCookieless(
				import.meta.env.VITE_LOOKER_HOST,
				aquireSession,
				generateTokens,
			);

			LookerEmbedSDK.createExploreWithId(import.meta.env.VITE_LOOKER_EXPLORE_ID)
				.withDialogScroll()
				.withAllowAttr("fullscreen")
				.appendTo(iframeContainerRef.current)
				.on("explore:ready", (event) => {
					const iframe = iframeContainerRef.current?.querySelector("iframe");
					if (iframe) {
						iframeContainerRef.current.style.display = "inline"; // Ensuring the iframe is visible only after loading
						iframe.style.width = "100%"; // Ensuring the iframe is full width
						iframe.style.height = "100%";
						iframe.style.overflow = "hidden"; // Ensuring the iframe is not scrollable
						iframe.scrolling = "no";
					}
					setIsLoading(false);
				})
				.on("explore:run:start", () => {
					setIsLoading(true);
				})
				.on("session:tokens", (event) => {
					if (event.session_reference_token_ttl === 0) {
						setShowRefreshDialog(true);
					}
				})
				.build()
				.connect();
		}

		start();

		return () => {
			const lookerIframe = iframeContainerRef.current?.querySelector("iframe");
			if (lookerIframe) {
				iframeContainerRef.current.style.display = "none";
				lookerIframe.remove();
			}
		};
	}, [namespace]);

	return (
		<div id="page-container" style={{ height: "100%", width: "100%" }}>
			<div style={{ display: "none" }} ref={iframeContainerRef} />
			{isLoading || showLoading ? (
				<div id="loader-container">
					<BounceLoader color={theme.palette.primary.loader} size={80} />
				</div>
			) : (
				<></>
			)}
			<RefreshDialog
				open={showRefreshDialog}
				countFrom={20}
				onClose={() => setShowRefreshDialog(false)}
			/>
		</div>
	);
}

export default LookerEmbed;

LookerEmbed.propTypes = {
	namespace: PropTypes.string,
	showLoading: PropTypes.bool,
};
