// biome-ignore lint/nursery/noUndeclaredDependencies: not sure why mui uses this base package
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { FormControl, FormHelperText, FormLabel } from "@mui/material";

export default (props) => {
	return (
		<FormControl>
			<FormLabel>
				{props.name}
				{props.required ? " *" : ""}
			</FormLabel>
			<TextareaAutosize
				required={props.required}
				minRows={5}
				variant="outlined"
				value={props.state ?? ""}
				onChange={(e) => props.setState(e.target.value)}
			/>
			<FormHelperText>{props.description}</FormHelperText>
		</FormControl>
	);
};
