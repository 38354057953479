import { Box, Divider } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import SingleAttachment from "./SingleAttachment";
import useServiceDeskUpload from "./useServiceDeskUpload";

export default (props) => {
	const [files, setFiles] = useState([]);
	const { isUploadingAttachment, uploadAttachment, file, jiraId, reset } =
		useServiceDeskUpload();

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (!file || !jiraId) {
			return;
		}

		const idx = files.findIndex((el) => el.file === file);
		if (idx === -1) {
			setFiles((f) => f.concat({ file, jiraId }));
		}
	}, [files, file, jiraId, setFiles]);

	const onAdd = useCallback(
		(inputEl) => {
			uploadAttachment(props.serviceDeskId, inputEl.target.files[0]);
		},
		[props.serviceDeskId, uploadAttachment],
	);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const onDelete = useCallback(
		(fileInput) => {
			reset();
			setFiles((lastState) => {
				const remaining = lastState.filter((ls) => ls.file !== fileInput);
				return remaining;
			});
		},
		[setFiles],
	);

	// biome-ignore lint/correctness/useExhaustiveDependencies: only update when files change
	useEffect(() => {
		props.setState(files.map((f) => f.jiraId));
	}, [files]);

	const requiredProp = files.length
		? { required: false }
		: { required: props.required };

	return (
		<Box
			sx={{
				pt: props.pt ?? 0,
			}}
		>
			<Divider
				sx={{ mb: 5 }}
			>{`${props.name} ${props.required ? "*" : ""}`}</Divider>
			{files.map((files, i) => (
				<SingleAttachment
					// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
					key={i}
					fileInput={files.file}
					sx={{ mb: 2 }}
					x
					includeDelete
					onDelete={onDelete}
					onChange={onAdd}
				/>
			))}
			<SingleAttachment
				onDelete={onDelete}
				onChange={onAdd}
				isLoading={isUploadingAttachment}
				{...props}
				{...requiredProp}
			/>
		</Box>
	);
};
