import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useState } from "react";
import { fetchIssue } from "../../Clients/NodeServer";
import { handleGetAccessToken } from "../../Helpers/auth";

export default () => {
	const [isFetchingIssue, setIsFetchingIssue] = useState(false);
	const [isRefetchingIssue, setIsRefetchingIssue] = useState(false);
	const [hasRefetchedIssue, setHasRefetchedIssue] = useState(false);
	const [hasFetchedIssue, setHasFetchedIssue] = useState(false);
	const [issue, setIssue] = useState([]);
	const [error, setError] = useState(null);

	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

	const fetchIssueCached = useCallback(
		(id) => {
			return handleGetAccessToken(getAccessTokenSilently, loginWithRedirect)
				.then((token) => {
					setIsFetchingIssue(true);
					return fetchIssue(token, id);
				})
				.then((response) => {
					setIssue(response.data);
					setHasFetchedIssue(true);
				})
				.catch((e) => {
					setHasFetchedIssue(true); // for now so we don't try and refetch
					setError(e);
					console.error(e);
				})
				.finally(() => setIsFetchingIssue(false));
		},
		[getAccessTokenSilently, loginWithRedirect],
	);

	const refetchIssueCached = useCallback(
		(id) => {
			return handleGetAccessToken(getAccessTokenSilently, loginWithRedirect)
				.then((token) => {
					setHasRefetchedIssue(false);
					setIsRefetchingIssue(true);
					return fetchIssue(token, id);
				})
				.then((response) => {
					setHasRefetchedIssue(true);
					setIssue(response.data);
				})
				.catch((e) => {
					setError(e);
					console.error(e);
				})
				.finally(() => setIsRefetchingIssue(false));
		},
		[getAccessTokenSilently, loginWithRedirect],
	);

	return {
		isFetchingIssue,
		isRefetchingIssue,
		hasRefetchedIssue,
		hasFetchedIssue,
		issue,
		error,
		refetchIssue: refetchIssueCached,
		fetchIssue: fetchIssueCached,
	};
};
