import * as nodeServer from "../Clients/NodeServer";

const initialState = {
	vessels: [],
	error: null,
	isFetchingVessels: false,
	hasFetchedVessels: false,
};

let _vessels = { ...initialState };

const vesselsSubscribers = new Set();

const store = {
	getVessels: () => {
		return _vessels;
	},
	setVessels: (vessels) => {
		_vessels = vessels;
		for (const cb of vesselsSubscribers) {
			cb();
		}
	},
	getSensorGroupBySensorId: (sensorId) => {
		for (const v of _vessels.vessels) {
			for (const s of v.sensorSchema) {
				if (s._id === sensorId) {
					return v;
				}
			}
		}
	},
	getSensorIdBySensorGroupId: (sensorGroupId) => {
		const sensorGroup = _vessels.vessels.find((v) => v._id === sensorGroupId);

		return sensorGroup?.sensorSchema.find((sensor) => sensor.name === "virt")
			?._id;
	},
	subscribeToVessels(callback) {
		vesselsSubscribers.add(callback);
		return () => vesselsSubscribers.delete(callback);
	},
};

const actions = {
	async fetchVessels(token, namespace) {
		if (_vessels.isFetching) {
			return;
		}

		store.setVessels({
			..._vessels,
			isFetching: true,
		});
		try {
			const resp = await nodeServer.fetchVessels(token, namespace);
			store.setVessels({
				..._vessels,
				vessels: resp.data,
				isFetchingVessels: false,
				hasFetchedVessels: true,
			});
		} catch (e) {
			store.setVessels({
				..._vessels,
				error: e,
				isFetchingVessels: false,
				hasFetchedVessels: true,
			});
		}
	},
};

export { store, actions };
