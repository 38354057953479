import { createTheme } from "@mui/material/styles";

export default createTheme({
	palette: {
		primary: {
			main: "#122040",
			loader: "#44ABDF",
		},
		secondary: {
			main: "#77CDAE",
		},
	},
});
