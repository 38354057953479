// biome-ignore lint/nursery/noUndeclaredDependencies: <explanation>
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { LoadingButton } from "@mui/lab";
import { FormControl, FormLabel } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useEffect, useState } from "react";
import MultiAttach from "../../Components/MultiAttach";
import usePostComment from "./usePostComment";

export default ({ issueKey, serviceDeskId, onSuccess, onFailure }) => {
	const [comment, setComment] = useState("");
	const [attachmentIds, setAttachmentIds] = useState([]);

	const {
		isPostingComment,
		hasPostedComment,
		error: commentSubmitError,
		postComment,
	} = usePostComment();

	useEffect(() => {
		if (hasPostedComment && !commentSubmitError) {
			onSuccess();
		}
	}, [onSuccess, hasPostedComment, commentSubmitError]);

	useEffect(() => {
		if (commentSubmitError) {
			onFailure();
		}
	}, [onFailure, commentSubmitError]);

	const submitComment = useCallback(() => {
		postComment({ issueKey, comment, attachmentIds });
	}, [attachmentIds, comment, postComment, issueKey]);

	return (
		<Box sx={{ pt: 4, display: "flex" }}>
			<FormControl sx={{ width: "100%" }}>
				<FormLabel>Add Comment</FormLabel>
				<TextareaAutosize
					minRows={5}
					variant="outlined"
					value={comment}
					onChange={(e) => setComment(e.target.value)}
					sx={{ border: 0, outline: 0 }}
				/>
				<MultiAttach
					pt={4}
					name="Add New Attachment"
					serviceDeskId={serviceDeskId}
					setState={setAttachmentIds}
					state={attachmentIds}
				/>
				<LoadingButton
					variant="contained"
					sx={{ mt: 4, width: "20%" }}
					disabled={!comment.length && !attachmentIds.length}
					loading={isPostingComment}
					onClick={submitComment}
				>
					Submit
				</LoadingButton>
			</FormControl>
		</Box>
	);
};
