// biome-ignore lint/nursery/noUndeclaredDependencies: This works fine, mui has multiple exports?
import { ClickAwayListener } from "@mui/base";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CloseIcon from "@mui/icons-material/Close";
import {
	InputAdornment,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	TextField,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { Stack } from "@mui/system";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import useFetchUsers from "./useFetchUsers";

function UserListItem({ user, onClick, withDivider }) {
	return (
		<>
			<ListItemButton onClick={onClick}>
				<ListItemText>
					{user.firstName} {user.lastName} ({user.email})
				</ListItemText>
			</ListItemButton>
			{withDivider ? <Divider variant="middle" component="li" /> : null}
		</>
	);
}

function LoadingUsers() {
	return (
		<ListItem>
			<BounceLoader color="#44ABDF" size={20} />
		</ListItem>
	);
}

export default (props) => {
	const { isFetchingUsers, hasFetchedUsers, fetchUsers, users, error } =
		useFetchUsers({ pageSize: 7 });
	const [searchParams] = useSearchParams();
	const [value, setValue] = useState("");
	const [showUsers, setShowUsers] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);

	const namespace = searchParams.get("n");

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const debouncedUserSearch = useCallback(debounce(fetchUsers, 750), [
		fetchUsers,
	]);

	useEffect(() => {
		debouncedUserSearch(namespace, value);
	}, [value, debouncedUserSearch, namespace]);

	const viewUsersAdornment = (
		<InputAdornment position="end">
			{isFetchingUsers ? (
				<LoadingUsers />
			) : (
				<IconButton
					sx={{ p: 0 }}
					disableFocusRipple
					onClick={() => setShowUsers(!showUsers)}
				>
					{showUsers ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
				</IconButton>
			)}
		</InputAdornment>
	);

	const removeUserAdornment = (
		<InputAdornment position="end">
			<IconButton
				sx={{ p: 0 }}
				disableFocusRipple
				onClick={() => {
					setValue("");
					setSelectedUser(null);
					debouncedUserSearch(namespace, "");
					props.setState(null);
				}}
			>
				<CloseIcon />
			</IconButton>
		</InputAdornment>
	);

	const selectedUserAsString = selectedUser
		? `${selectedUser.firstName} ${selectedUser.lastName} (${selectedUser.email})`
		: "";

	return (
		<Stack sx={{ display: "flex", width: "100%", position: "relative" }}>
			<ClickAwayListener onClickAway={() => setShowUsers(false)}>
				<TextField
					label={props.name}
					required={props.required}
					helperText={props.description}
					variant="outlined"
					value={selectedUserAsString || value}
					onChange={(e) => setValue(e.target.value)}
					sx={{ p: 0 }}
					onFocus={() => {
						setShowUsers(true);
					}}
					slotProps={{
						input: {
							endAdornment: selectedUser
								? removeUserAdornment
								: viewUsersAdornment,
						},
					}}
				/>
			</ClickAwayListener>
			<List
				spacing={1}
				sx={{
					background: "white",
					position: "absolute",
					display: showUsers && users.length ? "block" : "none",
					top: "4.8em",
					width: "inherit",
					zIndex: 2, //set zindex to ensure input label below are not visible, 2 seems to work
				}}
			>
				{users.map((u, i) => (
					<UserListItem
						key={u._id}
						withDivider={users.length - 1 !== i}
						user={u}
						onClick={() => {
							setSelectedUser(u);
							setShowUsers(false);
							props.setState([{ id: u.jiraId }]);
						}}
					/>
				))}
			</List>
		</Stack>
	);
};
