import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useState } from "react";
import { postServiceDeskRequest } from "../../Clients/NodeServer";
import { handleGetAccessToken } from "../../Helpers/auth";

export default () => {
	const [isPostingServiceDeskRequest, setIsPostingServiceDeskRequest] =
		useState(false);
	const [hasPostedServiceDeskRequest, setHasPostedServiceDeskRequest] =
		useState(false);
	const [response, setResponse] = useState(null);
	const [error, setError] = useState(null);

	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

	const postServiceDeskRequestCached = useCallback(
		(serviceDeskId, requestId, requestFieldValues, formValues) => {
			return handleGetAccessToken(getAccessTokenSilently, loginWithRedirect)
				.then((token) => {
					setIsPostingServiceDeskRequest(true);
					return postServiceDeskRequest(
						token,
						serviceDeskId,
						requestId,
						requestFieldValues,
						formValues,
					);
				})
				.then((response) => {
					setResponse(response.data);
					setHasPostedServiceDeskRequest(true);
				})
				.catch((e) => {
					setHasPostedServiceDeskRequest(true); // for now so we don't try and refetch
					setError(e);
					console.error(e);
				})
				.finally(() => setIsPostingServiceDeskRequest(false));
		},
		[getAccessTokenSilently, loginWithRedirect],
	);

	return {
		isPostingServiceDeskRequest,
		hasPostedServiceDeskRequest,
		response,
		error,
		postServiceDeskRequest: postServiceDeskRequestCached,
	};
};
