import { Box, Divider, Snackbar, Stack } from "@mui/material";
import { Alert } from "@mui/material";
import Grid from "@mui/material/Grid2";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import PageHeader from "../../Components/PageHeader";
import useFetchIssues from "./useFetchIssues";

dayjs.extend(relativeTime);

export default () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const namespace = searchParams.get("n");
	const [showSnackbar, setShowSnackbar] = useState(false);
	const { isFetchingIssues, hasFetchedIssues, issues, error, fetchIssues } =
		useFetchIssues();

	useEffect(() => {
		if (!hasFetchedIssues) {
			fetchIssues();
		}
	}, [fetchIssues, hasFetchedIssues]);

	useEffect(() => {
		if (error) {
			setShowSnackbar(true);
		}
	}, [error]);

	return (
		<>
			<PageHeader>Service Requests</PageHeader>

			{isFetchingIssues || !hasFetchedIssues ? (
				<Box
					sx={{
						position: "absolute",
						top: "50vh",
						left: "50vw",
						transform: "translate(-50%, -50%)",
					}}
				>
					<BounceLoader color="#44ABDF" size={80} />
				</Box>
			) : issues.length ? (
				<Stack sx={{ px: 2 }} divider={<Divider flexItem />}>
					<Grid
						container
						sx={{
							color: "#03143d",
							fontWeight: 600,
							fontSize: "1em",
							px: 2,
							py: 3,
						}}
					>
						<Grid size={{ xs: 2 }} display="flex" alignItems="center">
							Type
						</Grid>
						<Grid size={{ xs: 3 }} display="flex" alignItems="center">
							Summary
						</Grid>
						<Grid size={{ xs: 2 }} display="flex" alignItems="center">
							Status
						</Grid>
						<Grid size={{ xs: 2 }} display="flex" alignItems="center">
							Reference
						</Grid>
						<Grid size={{ xs: 2 }} display="flex" alignItems="center">
							Last Activity
						</Grid>
						<Grid size={{ xs: 1 }} />
					</Grid>
					{issues.map((i) => (
						<Grid
							container
							key={i.key}
							sx={{
								py: 3,
								px: 2,
								cursor: "pointer",
								":hover": { backgroundColor: "#eceef3" },
							}}
							onClick={() => navigate(`/helpcenter/requests/${i.key}`)}
						>
							<Grid size={{ xs: 2 }} display="flex" alignItems="center">
								{i.issueType}
							</Grid>
							<Grid size={{ xs: 3 }} display="flex" alignItems="center">
								{i.summary}
							</Grid>
							<Grid size={{ xs: 2 }} display="flex" alignItems="center">
								{i.status}
							</Grid>
							<Grid size={{ xs: 2 }} display="flex" alignItems="center">
								{i.key}
							</Grid>
							<Grid size={{ xs: 2 }} display="flex" alignItems="center">
								{dayjs(i.updated).fromNow()}
							</Grid>
							<Grid size={{ xs: 1 }} display="flex" />
						</Grid>
					))}
				</Stack>
			) : (
				<Box
					height="50%"
					width="100%"
					alignItems="center"
					display="flex"
					justifyContent="center"
				>
					<h3>No requests submitted</h3>
				</Box>
			)}
			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				open={showSnackbar}
				autoHideDuration={6000}
				onClose={() => {
					setShowSnackbar(false);
				}}
			>
				<Alert severity={"error"} sx={{ width: "100%" }}>
					Something went wrong. Please try again later.
				</Alert>
			</Snackbar>
		</>
	);
};
