import { useAuth0 } from "@auth0/auth0-react";
import Box from "@mui/material/Box";
import { useEffect, useState, useSyncExternalStore } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import ExportTracker from "../Components/ExportTacker";
import NamespaceDialog from "../Components/NamespaceDialog";
import TopBar from "../Components/TopBar";
import { handleGetAccessToken } from "../Helpers/auth";
import useCachedNamespace from "../Hooks/useCachedNamespace";
import { actions as meActions, store as meStore } from "../Stores/Me";
import {
	actions as namespaceActions,
	store as namespaceStore,
} from "../Stores/Namespace";

// Set namespace if it is not in the query params
// Fetch user and permissions
// Initialize all available namespaces
function BaseContainer() {
	const [cachedNamespace, setCachedNamespace] = useCachedNamespace();
	const [showNamespaceSelection, setShowNamespaceSelection] = useState(false);
	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

	const { available: availableNamespaces } = useSyncExternalStore(
		namespaceStore.subscribeToNamespaces,
		namespaceStore.getNamespaces,
	);

	const {
		permissions,
		isFetched: isUserFetched,
		isFetching: isFetchingUser,
	} = useSyncExternalStore(meStore.subscribeToMe, meStore.getMe);

	const [searchParams, setSearchParams] = useSearchParams();
	const namespaceQueryParams = searchParams.get("n");

	// Initialize list of authorized namespaces
	useEffect(() => {
		if (permissions) {
			namespaceActions.setAvailable(
				permissions
					.filter((p) => p.name === "lookerDashboard:read")
					.map((p) => p.definition.namespace),
			);
		}
	}, [permissions]);

	// Fetch user and permissions
	useEffect(() => {
		if (!isFetchingUser && !isUserFetched) {
			handleGetAccessToken(getAccessTokenSilently, loginWithRedirect).then(
				(token) => {
					meActions.fetchMe(token);
				},
			);
		}
	}, [
		isUserFetched,
		isFetchingUser,
		getAccessTokenSilently,
		loginWithRedirect,
	]);

	//set n query param
	useEffect(() => {
		if (namespaceQueryParams && namespaceQueryParams === cachedNamespace) {
			setShowNamespaceSelection(false);
			return;
		}

		if (namespaceQueryParams && namespaceQueryParams !== cachedNamespace) {
			setCachedNamespace(namespaceQueryParams);
		} else if (cachedNamespace && namespaceQueryParams !== cachedNamespace) {
			setSearchParams({ n: cachedNamespace }, { replace: true });
		} else if (!cachedNamespace && availableNamespaces.length === 1) {
			setSearchParams({ n: availableNamespaces[0] }, { replace: true });
		} else if (
			!cachedNamespace &&
			!namespaceQueryParams &&
			availableNamespaces.length > 1
		) {
			setShowNamespaceSelection(true);
		}
	}, [
		availableNamespaces,
		cachedNamespace,
		namespaceQueryParams,
		setSearchParams,
		setCachedNamespace,
	]);

	return (
		<Box display="flex" flexDirection="column" height="100%">
			<TopBar />
			<Outlet />

			<NamespaceDialog
				namespaces={availableNamespaces}
				open={showNamespaceSelection}
				onClose={(value) => {
					setShowNamespaceSelection(false);
				}}
				onSelect={(value) => {
					setSearchParams({ n: value }, { replace: true });
				}}
			/>
			<ExportTracker />
		</Box>
	);
}

export default BaseContainer;
