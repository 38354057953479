import { Attachment } from "@mui/icons-material";
import { Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import React from "react";

dayjs.extend(relativeTime);

function ConfiguredAvatar({ name, links }) {
	const src = links?.avatarUrls?.["48x48"];
	return src ? (
		<Avatar alt={name} src={src} />
	) : (
		<Avatar alt={name}> {name[0]}</Avatar>
	);
}

function findAndReplaceAttachments(text) {
	let matches = text.matchAll(/\[\^(.*?)\](?:\s_\(.*?\)_)?/g);
	const attachments = [];
	for (const m of matches) {
		attachments.push(m.at(1));
	}
	matches = text.matchAll(/\!(.*?)\|.*?\!/g);
	for (const m of matches) {
		attachments.push(m.at(1));
	}
	return {
		text: text
			.replaceAll(/\[\^(.*?)\](?:\s_\(.*?\)_)?/g, "")
			.replaceAll(/\!(.*?)\|.*?\!/g, ""),
		attachments,
	};
}

function Title({ name, date }) {
	return (
		<Box>
			<Typography sx={{ display: "inline" }}>{name}</Typography>
			<Typography sx={{ fontStyle: "italic", display: "inline", ml: 1 }}>
				{date}
			</Typography>
		</Box>
	);
}
export default React.forwardRef(({ created, author, body }, ref) => {
	const { text, attachments } = findAndReplaceAttachments(body);
	return (
		<Box ref={ref}>
			<Stack direction={"row"}>
				<ConfiguredAvatar name={author.displayName} links={author._links} />
				<Box sx={{ pl: 1, pt: ".25em" }}>
					<Title
						name={author.displayName}
						date={dayjs(created.epochMillis).fromNow()}
					/>
					<Box sx={{ mt: 2 }}>{text}</Box>
					{attachments.length ? (
						<Box sx={{ mt: 2 }}>
							{attachments.map((a) => (
								<Typography
									key={a}
									sx={{
										display: "flex",
										alignItems: "center",
										fontWeight: 700,
									}}
								>
									<Attachment sx={{ mr: 1 }} />
									{a}
								</Typography>
							))}
						</Box>
					) : (
						<></>
					)}
				</Box>
			</Stack>
		</Box>
	);
});
