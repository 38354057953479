import { AttachFile } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
export default ({ attachments, defaultExpanded = true }) => {
	return (
		<Accordion {...{ defaultExpanded }} sx={{ width: "100%" }} square>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<AttachFile sx={{ mr: 1 }} />
				Attachments ({attachments.length})
			</AccordionSummary>
			<AccordionDetails>
				<Stack spacing={4}>
					{attachments.map((a, i) => (
						<TextField
							key={`${a.filename}-${i}`}
							value={a.filename}
							disabled
							fullWidth
						/>
					))}
				</Stack>
			</AccordionDetails>
		</Accordion>
	);
};
