import "./styles.css";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";

export default () => {
	const { err } = useParams();

	return (
		<Box
			height="100%"
			width="100%"
			alignItems="center"
			display="flex"
			flexDirection="column"
			justifyContent="center"
		>
			<h2>Page Not Found</h2>
			<p>
				Sorry, the page you are looking for does not exist or cannot be
				accessed. If the problem persists, contact your administrator.{" "}
				<i>{err}</i>
			</p>
		</Box>
	);
};
