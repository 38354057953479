import { isEqual } from "lodash";
const initialState = {
	available: [],
};

let _namespaces = { ...initialState };

const namespaceSubscribers = new Set();

const store = {
	setNamespaces: (namespace) => {
		_namespaces = namespace;
		for (const cb of namespaceSubscribers) {
			cb();
		}
	},
	getNamespaces: () => {
		return _namespaces;
	},
	subscribeToNamespaces(callback) {
		namespaceSubscribers.add(callback);
		return () => namespaceSubscribers.delete(callback);
	},
};

const actions = {
	setAvailable: (namespaces) => {
		if (!isEqual(_namespaces.available, namespaces)) {
			store.setNamespaces({
				..._namespaces,
				available: namespaces,
			});
		}
	},
	resetState: () => {
		store.setNamespaces({
			..._namespaces,
		});
	},
};

export { store, actions };
