import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useState } from "react";
import { startExport } from "../../Clients/NodeServer";
import { handleGetAccessToken } from "../../Helpers/auth";

export default () => {
	const [isStartingExport, setIsStartingExport] = useState(false);
	const [hasStartedExport, setHasStartedExport] = useState(false);
	const [response, setResponse] = useState(null);
	const [error, setError] = useState(null);

	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

	const startExportCached = useCallback(
		({ start, end, sensorId }) => {
			return handleGetAccessToken(getAccessTokenSilently, loginWithRedirect)
				.then((token) => {
					setIsStartingExport(true);
					return startExport(token, {
						start,
						end,
						sensorId,
					});
				})
				.then((response) => {
					setResponse(response.data);
					setHasStartedExport(true);
				})
				.catch((e) => {
					setHasStartedExport(true); // for now so we don't try and refetch
					setError(e);
					console.error(e);
				})
				.finally(() => setIsStartingExport(false));
		},
		[getAccessTokenSilently, loginWithRedirect],
	);

	return {
		isStartingExport,
		hasStartedExport,
		response,
		error,
		startExport: startExportCached,
	};
};
