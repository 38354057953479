import { Delete, FileUploadOutlined } from "@mui/icons-material";
import { IconButton, TextField } from "@mui/material";
import { BounceLoader } from "react-spinners";

export default (props) => {
	const disabledFileBrowse = props.isLoading || !!props.includeDelete;
	const EndAdornmentIcon = () => {
		if (props.includeDelete) {
			return <Delete />;
		}
		if (props.isLoading) {
			return <BounceLoader color="#44ABDF" size={20} />;
		}
		return <FileUploadOutlined />;
	};

	return (
		<>
			<TextField
				variant="standard"
				label={props.label ?? ""}
				type="text"
				fullWidth
				disabled
				helperText={
					props.includeDelete ? "" : "Select icon on right to browse for file"
				}
				required={props.required}
				value={props?.fileInput?.name ?? ""}
				sx={{
					...(props.sx ?? {}),
				}}
				InputProps={{
					endAdornment: (
						<IconButton
							component="label"
							disabled={props.isLoading}
							{...(props.includeDelete
								? { onClick: () => props.onDelete?.(props.fileInput) }
								: {})}
						>
							<EndAdornmentIcon />
							<input
								styles={{ display: "none", width: "100%" }}
								type="file"
								hidden
								disabled={disabledFileBrowse}
								onChange={props.onChange}
							/>
						</IconButton>
					),
				}}
			/>
		</>
	);
};
