import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { blue } from "@mui/material/colors";
import PropTypes from "prop-types";

function NamespaceDialog(props) {
	const { onClose, onSelect, selectedValue, open, namespaces } = props;

	return (
		<Dialog open={open}>
			<DialogTitle>Select Namespace</DialogTitle>
			<List sx={{ p: 0 }}>
				{namespaces.map((ns) => (
					<ListItem disableGutters key={ns} sx={{ p: 0 }}>
						<ListItemButton onClick={() => onSelect(ns)}>
							<ListItemAvatar>
								<Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
									<SpaceDashboardIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary={ns} />
						</ListItemButton>
					</ListItem>
				))}
			</List>
			<Button onClick={onClose} variant="text" sx={{ m: 2 }}>
				Cancel
			</Button>
		</Dialog>
	);
}

NamespaceDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	namespaces: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default NamespaceDialog;
