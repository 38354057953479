import { FormControl, FormLabel, Paper } from "@mui/material";
import { Divider } from "@mui/material";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Box } from "@mui/system";
import { useCallback, useEffect, useMemo, useRef } from "react";
import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import PageHeader from "../../Components/PageHeader";
import SpHeaderBlack from "../../Components/SpHeaderBlack";
import useServiceDeskRequestForm from "../../Hooks/useServiceDeskRequestForm";
import AddComment from "./AddComment";
import AttachmentsAccordian from "./AttachmentsAccordian";
import Comment from "./Comment";
import FormAccordian from "./FormAccordian";
import useFetchIssue from "./useFetchIssue";

function renderRequestField(schema, label, value) {
	switch (schema.type) {
		case "date":
		case "string":
			if (schema.system === "summary") {
				return (
					<FormControl sx={{ width: "100%" }}>
						<FormLabel>{label}</FormLabel>
						<TextareaAutosize
							minRows={5}
							variant="outlined"
							disabled
							value={value}
						/>
					</FormControl>
				);
			}
			return <TextField label={label} value={value} disabled fullWidth />;
		case "option":
			return <TextField label={label} value={value.value} disabled fullWidth />;
		case "array":
			if (schema.items === "user") {
				return (
					<FormControl sx={{ width: "100%" }}>
						<FormLabel>{label}</FormLabel>
						<TextareaAutosize
							minRows={5}
							variant="outlined"
							disabled
							value={value
								.map((v) => `${v.displayName} (${v.emailAddress})`)
								.join(", ")}
						/>
					</FormControl>
				);
			}
			return;
		default:
			console.error(`cannot render ${schema.type}`);
			return null;
	}
}

export default () => {
	const { jiraKey } = useParams();
	const [showSnackbar, setShowSnackbar] = useState(false);
	const lastComment = useRef(null);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const {
		isRefetchingIssue,
		hasRefetchedIssue,
		refetchIssue,
		isFetchingIssue,
		hasFetchedIssue,
		issue,
		error,
		fetchIssue,
	} = useFetchIssue();

	const {
		isFetchingRequestForm,
		hasFetchedRequestForm,
		requestFields,
		formFields,
		fetchRequestForm,
	} = useServiceDeskRequestForm();

	useEffect(() => {
		if (isFetchingIssue || hasFetchedIssue) {
			return;
		}

		fetchIssue(jiraKey);
	}, [hasFetchedIssue, isFetchingIssue, fetchIssue, jiraKey]);

	useEffect(() => {
		if (
			isFetchingRequestForm ||
			hasFetchedRequestForm ||
			isFetchingIssue ||
			!hasFetchedIssue
		) {
			return;
		}
		fetchRequestForm(
			issue.metadata.serviceDeskId,
			issue.metadata.requestTypeId,
		);
	}, [
		hasFetchedRequestForm,
		hasFetchedIssue,
		issue,
		isFetchingIssue,
		isFetchingRequestForm,
		fetchRequestForm,
	]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: this does need to be re-run everytime the lastComment changes
	useEffect(() => {
		if (hasRefetchedIssue) {
			window.scrollTo({
				top: lastComment.current.offsetTop,
				behavior: "smooth",
			});
		}
	}, [hasRefetchedIssue, lastComment.current]);

	const fieldLookup = useMemo(() => {
		if (!requestFields) {
			return {};
		}

		return requestFields.reduce((acc, field) => {
			acc[field.fieldId] = field.jiraSchema;
			return acc;
		}, {});
	}, [requestFields]);

	const onCommentPost = useCallback(() => {
		refetchIssue(jiraKey);
	}, [jiraKey, refetchIssue]);

	const onFailedCommentPost = useCallback(() => {
		setSnackbarMessage("Something when wrong, please try again later.");
		setShowSnackbar(true);
	}, []);

	return (isFetchingIssue && !hasFetchedIssue) ||
		!hasFetchedIssue ||
		isFetchingRequestForm ||
		!hasFetchedRequestForm ? (
		<Box
			sx={{
				position: "absolute",
				top: "50vh",
				left: "50vw",
				transform: "translate(-50%, -50%)",
			}}
		>
			<BounceLoader color="#44ABDF" size={80} />
		</Box>
	) : (
		<>
			<PageHeader>Request {issue.metadata.issueKey}</PageHeader>
			<Paper
				sx={{
					mx: {
						xs: 5,
						md: 20,
						lg: 40,
						xl: 60,
					},
					pt: 6,
					mt: 2,
					pb: 8,
					px: {
						xs: 2,
						md: 8,
					},
				}}
				square
			>
				<Stack justifyContent="center" alignItems="center" spacing={4}>
					{issue.metadata.requestFieldValues.map((rv) => (
						<Box key={rv.fieldId} sx={{ width: "100%" }}>
							{renderRequestField(fieldLookup[rv.fieldId], rv.label, rv.value)}
						</Box>
					))}

					{issue.forms.map((form) => (
						<React.Fragment key={form.metadata.id}>
							<FormAccordian answers={form.answers} metadata={form.metadata} />
						</React.Fragment>
					))}

					<AttachmentsAccordian
						attachments={issue.attachments}
						defaultExpanded={false}
					/>
				</Stack>
				<SpHeaderBlack sx={{ mt: 8, mb: 2 }}> Activity: </SpHeaderBlack>
				<Stack spacing={2} divider={<Divider />}>
					{isRefetchingIssue ? (
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<BounceLoader color="#44ABDF" size={80} />
						</Box>
					) : issue.comments.length ? (
						issue.comments.map((c) => (
							<React.Fragment key={c.id}>
								<Comment {...c} ref={lastComment} />
							</React.Fragment>
						))
					) : (
						<Box>None yet.</Box>
					)}
				</Stack>

				{issue && !isRefetchingIssue ? (
					<AddComment
						issueKey={jiraKey}
						serviceDeskId={issue.metadata.serviceDeskId}
						onFailure={onFailedCommentPost}
						onSuccess={onCommentPost}
					/>
				) : null}
			</Paper>
			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				open={showSnackbar}
				autoHideDuration={6000}
				onClose={() => setShowSnackbar(false)}
			>
				<Alert severity={"error"} sx={{ width: "100%" }}>
					{snackbarMessage}
				</Alert>
			</Snackbar>
		</>
	);
};
