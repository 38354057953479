import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useState } from "react";
import { fetchExports } from "../../Clients/NodeServer";
import { handleGetAccessToken } from "../../Helpers/auth";

export default () => {
	const [isFetchingExports, setIsFetchingExports] = useState(false);
	const [hasFetchedExports, setHasFetchedExports] = useState(false);
	const [response, setResponse] = useState(null);
	const [error, setError] = useState(null);

	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

	const fetchExportsCached = useCallback((start, end) => {
		return handleGetAccessToken(getAccessTokenSilently, loginWithRedirect)
			.then((token) => {
				setIsFetchingExports(true);
				return fetchExports(token, { start, end });
			})
			.then((response) => {
				setResponse(response.data);
				setHasFetchedExports(true);
			})
			.catch((e) => {
				setHasFetchedExports(true); // for now so we don't try and refetch
				setError(e);
				console.error(e);
			})
			.finally(() => setIsFetchingExports(false));
	});

	return {
		isFetchingExports,
		hasFetchedExports,
		response,
		error,
		fetchExports: fetchExportsCached,
	};
};
