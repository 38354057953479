import Box from "@mui/material/Box";

export default ({ children, sx = {} }) => {
	return (
		<Box
			sx={{
				pt: "30px",
				height: "34px",
				fontWeight: "600",
				fontSize: "1.25em",
				...sx,
			}}
		>
			{children}
		</Box>
	);
};
