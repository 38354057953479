import { useMemo } from "react";
import { useSyncExternalStore } from "react";
import { useParams } from "react-router-dom";
import { store as dashboardStore } from "../Stores/Dashboard";

function useSelectedDashboard(namespace) {
	const { slug } = useParams();

	const { dashboardMenus } = useSyncExternalStore(
		dashboardStore.subscribeToDashboards,
		dashboardStore.getDashboards,
	);
	const dashboard = useMemo(() => {
		if (!namespace || !slug) {
			return;
		}

		const selectedDashboardMenus = (
			dashboardMenus.find((d) => d._id === namespace)?.dashboards ?? []
		).flatMap((ds) => ds.items);

		return selectedDashboardMenus.find((d) => d.slug === slug);
	}, [namespace, slug, dashboardMenus]);

	return dashboard;
}

export default useSelectedDashboard;
