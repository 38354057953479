import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export default ({
	state = { id: "" },
	fieldId,
	name,
	setState,
	validValues,
	required,
}) => {
	const label = `${name}${required ? " *" : ""}`;
	return (
		<FormControl fullWidth>
			<InputLabel id={`${fieldId}-select-label`}>{label}</InputLabel>
			<Select
				labelId={`${fieldId}-select-label`}
				id={`${fieldId}-select`}
				value={state.id}
				label={label}
				onChange={(e) => setState({ id: e.target.value })}
			>
				{validValues.map((v) => (
					<MenuItem key={v.value} value={v.value}>
						{v.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};
