import Box from "@mui/material/Box";

export default (props) => {
	return (
		<Box px={4} borderBottom="1px solid #dbdbdb">
			<Box
				sx={{
					fontSize: "22px",
					fontWeight: 500,
					height: "64px",
					display: "flex",
					alignItems: "center",
					color: "#03143d",
				}}
			>
				{props.children}
			</Box>
		</Box>
	);
};
