import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useState } from "react";
import { attachToIssue } from "../../Clients/NodeServer";
import { handleGetAccessToken } from "../../Helpers/auth";

export default () => {
	const [isAttachingToIssue, setIsAttachingToIssue] = useState(false);
	const [hasAttachedToIssue, setHasAttachedToIssue] = useState(false);
	const [response, setResponse] = useState([]);
	const [error, setError] = useState(null);

	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

	const attachToIssueCached = useCallback(
		(serviceDeskId, requestId) => {
			return handleGetAccessToken(getAccessTokenSilently, loginWithRedirect)
				.then((token) => {
					setIsAttachingToIssue(true);
					return attachToIssue(token, serviceDeskId, requestId);
				})
				.then((response) => {
					setResponse(response.data);
					setHasAttachedToIssue(true);
				})
				.catch((e) => {
					setHasAttachedToIssue(true); // for now so we don't try and refetch
					setError(e);
					console.error(e);
				})
				.finally(() => setIsAttachingToIssue(false));
		},
		[getAccessTokenSilently, loginWithRedirect],
	);

	return {
		isAttachingToIssue,
		hasAttachedToIssue,
		response,
		error,
		attachToIssue: attachToIssueCached,
	};
};
