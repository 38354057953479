import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useState } from "react";
import { fetchServiceDeskRequestType } from "../../Clients/NodeServer";
import { handleGetAccessToken } from "../../Helpers/auth";

export default () => {
	const [isFetchingServiceDeskRequest, setIsFetchingServiceDeskRequest] =
		useState(false);
	const [hasFetchedServiceDeskRequest, setHasFetchedServiceDeskRequest] =
		useState(false);
	const [serviceDeskRequest, setServiceDeskRequest] = useState(null);
	const [error, setError] = useState(null);

	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

	const fetchServiceDeskRequestCached = useCallback(
		(serviceDeskId, requestId) => {
			return handleGetAccessToken(getAccessTokenSilently, loginWithRedirect)
				.then((token) => {
					setIsFetchingServiceDeskRequest(true);
					return fetchServiceDeskRequestType(token, serviceDeskId, requestId);
				})
				.then((response) => {
					setServiceDeskRequest(response.data);
					setHasFetchedServiceDeskRequest(true);
				})
				.catch((e) => {
					setHasFetchedServiceDeskRequest(true); // for now so we don't try and refetch
					setError(e);
					console.error(e);
				})
				.finally(() => setIsFetchingServiceDeskRequest(false));
		},
		[getAccessTokenSilently, loginWithRedirect],
	);

	return {
		isFetchingServiceDeskRequest,
		hasFetchedServiceDeskRequest,
		serviceDeskRequest,
		error,
		fetchServiceDeskRequest: fetchServiceDeskRequestCached,
	};
};
