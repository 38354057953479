import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useState } from "react";
import { fetchExport } from "../../Clients/NodeServer";
import { handleGetAccessToken } from "../../Helpers/auth";

export default () => {
	const [isFetchingExport, setIsFetchingExport] = useState(false);
	const [hasFetchedExport, setHasFetchedExport] = useState(false);
	const [response, setResponse] = useState(null);
	const [error, setError] = useState(null);

	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

	const fetchExportCached = useCallback(
		(id) => {
			return handleGetAccessToken(getAccessTokenSilently, loginWithRedirect)
				.then((token) => {
					setIsFetchingExport(true);
					return fetchExport(token, id);
				})
				.then((response) => {
					setResponse(response.data);
					setHasFetchedExport(true);
				})
				.catch((e) => {
					setHasFetchedExport(true); // for now so we don't try and refetch
					setError(e);
					console.error(e);
				})
				.finally(() => setIsFetchingExport(false));
		},
		[getAccessTokenSilently, loginWithRedirect],
	);
	return {
		isFetchingExport,
		hasFetchedExport,
		response,
		error,
		fetchExport: fetchExportCached,
	};
};
