import daysjs from "dayjs";
import * as nodeServer from "../Clients/NodeServer";

const initialState = {
	activeExports: [],
	error: null,
	isExportReady: false, //flag used to refresh the dashboardPage once the export is ready
	isFetching: false,
	isFetched: false,
};

let _exports = { ...initialState };

const exportsSubscribers = new Set();

const store = {
	getExports: () => {
		return _exports;
	},
	setExports: (dashboards) => {
		_exports = dashboards;
		for (const cb of exportsSubscribers) {
			cb();
		}
	},
	subscribeToExports(callback) {
		exportsSubscribers.add(callback);
		return () => exportsSubscribers.delete(callback);
	},
};

const actions = {
	setIsExportReady(toggle) {
		store.setExports({
			..._exports,
			isExportReady: toggle,
		});
	},
	async fetchInProgressExports(token) {
		if (_exports.isFetching) {
			return;
		}

		store.setExports({
			..._exports,
			isFetching: true,
		});
		try {
			//From past 30 mins to now
			const resp = await nodeServer.fetchExports(token, {
				inProgress: true,
				start: daysjs().subtract(30, "minute").toDate(),
				end: new Date(),
			});
			store.setExports({
				..._exports,
				activeExports: resp.data,
				isFetching: false,
				isFetched: true,
			});
		} catch (e) {
			store.setExports({
				..._exports,
				error: e,
				isFetching: false,
				isFetched: true,
			});
		}
	},
};

export { store, actions };
