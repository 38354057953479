import Box from "@mui/material/Box";

export default ({ children, sx = {} }) => {
	return (
		<Box
			sx={{
				pt: "20px",
				height: "64px",
				color: "#03143d",
				fontWeight: 600,
				fontSize: "16px",
				display: "flex",
				alignItems: "center",
				...sx,
			}}
		>
			{children}
		</Box>
	);
};
