// biome-ignore lint/nursery/noUndeclaredDependencies: not sure why mui uses this base package
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { FormControl, FormHelperText, FormLabel } from "@mui/material";
import { get } from "lodash";

export default (props) => {
	return (
		<FormControl>
			<FormLabel>
				{props.name}
				{props.required ? " *" : ""}
			</FormLabel>
			<TextareaAutosize
				required={props.required}
				minRows={5}
				variant="outlined"
				value={get(props.state, "content.0.content.0.text") ?? ""}
				onChange={(e) =>
					props.setState({
						version: 1,
						type: "doc",
						content: [
							{
								type: "paragraph",
								content: [
									{
										type: "text",
										text: e.target.value,
									},
								],
							},
						],
					})
				}
			/>
			<FormHelperText>{props.description}</FormHelperText>
		</FormControl>
	);
};
