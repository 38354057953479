import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

function formatDate(d) {
	return dayjs(d).format("YYYY-MM-DD");
}

export default ({ value, name, setState, required }) => {
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DatePicker
				label={name}
				slotProps={{
					textField: {
						required,
					},
				}}
				value={value ? dayjs(value) : value}
				onChange={(d) => setState(formatDate(d))}
			/>
		</LocalizationProvider>
	);
};
