import { TextField } from "@mui/material";

export default (props) => {
	return (
		<TextField
			label={props.name}
			required={props.required}
			helperText={props.description}
			variant="outlined"
			value={props.state ?? ""}
			onChange={(e) => props.setState(e.target.value)}
		/>
	);
};
