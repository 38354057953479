import * as nodeServer from "../Clients/NodeServer";

const initialState = {
	users: [],
	next: null,
	error: null,
	lastParams: {},
	isFetching: false,
	isFetched: false,
	isCreating: false,
	isCreated: false,
};

let _users = { ...initialState };

const usersSubscribers = new Set();

const store = {
	getUsers: () => {
		return _users;
	},
	setUsers: async (users) => {
		_users = users;
		for (const cb of usersSubscribers) {
			cb();
		}
	},
	subscribeToUsers(callback) {
		usersSubscribers.add(callback);
		return () => usersSubscribers.delete(callback);
	},
};

const actions = {
	async fetchUsers(token, { namespace, pageSize, query }) {
		if (_users.isFetching) {
			return;
		}
		store.setUsers({
			..._users,
			lastParams: { namespace, pageSize, query },
			isFetching: true,
		});
		try {
			const resp = await nodeServer.getUsers(token, {
				namespace,
				pageSize,
				query,
			});
			store.setUsers({
				..._users,
				next: resp.data.next,
				users: resp.data.users,
				isFetching: false,
				isFetched: true,
			});
		} catch (e) {
			console.error(e);
			store.setUsers({
				..._users,
				error: e,
				isFetching: false,
				isFetched: true,
			});
		}
	},
	async fetchNext(token) {
		if (_users.isFetching || !_users.next) {
			return;
		}
		store.setUsers({
			..._users,
			isFetchingNext: true,
		});
		try {
			const resp = await nodeServer.getUsers(token, {
				namespace: _users.lastParams.namespace,
				pageSize: _users.lastParams.pageSize,
				nextToken: _users.next,
				query: _users.lastParams.query,
			});
			store.setUsers({
				..._users,
				next: resp.data.next,
				users: _users.users.concat(resp.data.users),
				isFetchingNext: false,
			});
		} catch (e) {
			console.error(e);
			store.setUsers({
				..._users,
				error: e,
				isFetchingNext: false,
			});
		}
	},
	async createUser(token, { firstName, lastName, email, namespace, password }) {
		if (_users.isCreating) {
			return;
		}
		store.setUsers({
			..._users,
			isCreating: true,
			createError: null,
		});
		try {
			const resp = await nodeServer.createUser(
				token,
				{
					firstName,
					lastName,
					email,
					namespace,
					password,
				},
				{
					skipPasswordEmail: !!password,
				},
			);
			store.setUsers({
				..._users,
				next: resp.data.next,
				users: resp.data.users,
				isCreating: false,
				isCreated: true,
			});
		} catch (e) {
			console.error(e.response.data);
			store.setUsers({
				..._users,
				error: e,
				isCreating: false,
				isCreated: false,
				createError: e.response.data,
			});
		}
	},
	resetState() {
		store.setUsers({ ...initialState });
	},
	resetCreateUserState() {
		store.setUsers({
			..._users,
			isCreate: false,
			createError: null,
			isCreating: false,
		});
	},
};

export { store, actions };
