import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useState } from "react";
import { fetchServiceDesks } from "../../Clients/NodeServer";
import { handleGetAccessToken } from "../../Helpers/auth";

export default () => {
	const [isFetchingServiceDesks, setIsFetchingServiceDesks] = useState(false);
	const [hasFetchedServiceDesks, setHasFetchedServiceDesks] = useState(false);
	const [response, setResponse] = useState([]);
	const [error, setError] = useState(null);

	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

	const fetchServiceDesksCached = useCallback(
		(page) => {
			return handleGetAccessToken(getAccessTokenSilently, loginWithRedirect)
				.then((token) => {
					setIsFetchingServiceDesks(true);
					return fetchServiceDesks(token, page);
				})
				.then((response) => {
					setResponse(response.data.values);
					setHasFetchedServiceDesks(true);
				})
				.catch((e) => {
					setHasFetchedServiceDesks(true); // for now so we don't try and refetch
					setError(e);
					console.error(e);
				})
				.finally(() => setIsFetchingServiceDesks(false));
		},
		[getAccessTokenSilently, loginWithRedirect],
	);

	return {
		isFetchingServiceDesks,
		hasFetchedServiceDesks,
		response,
		error,
		fetchServiceDesks: fetchServiceDesksCached,
	};
};
