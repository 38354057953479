import LinkIcon from "@mui/icons-material/Link";
import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import { useEffect } from "react";
import {
	Link,
	useLocation,
	useParams,
	useSearchParams,
} from "react-router-dom";
import { BounceLoader } from "react-spinners";
import PageHeader from "../../Components/PageHeader";
import SpHeader from "../../Components/SpHeader";
import SpHeaderBlack from "../../Components/SpHeaderBlack";
import useServiceDesk from "./useServiceDesk";
import useServiceDeskRequests from "./useServiceDeskRequests";

export default () => {
	const { state } = useLocation();
	const { serviceDeskId } = useParams();

	const {
		response: fetchedServiceDesk,
		isFetchingServiceDesk,
		hasFetchedServiceDesk,
		fetchServiceDesk,
	} = useServiceDesk();

	const {
		response: serviceDeskRequests,
		isFetchingServiceDeskRequests,
		hasFetchedServiceDeskRequests,
		fetchServiceDeskRequests,
	} = useServiceDeskRequests(serviceDeskId);

	const [searchParams] = useSearchParams();

	const selectedNamespace = searchParams.get("n");

	useEffect(() => {
		if (
			!state?.serviceDesk &&
			!isFetchingServiceDesk &&
			!hasFetchedServiceDesk
		) {
			fetchServiceDesk(serviceDeskId);
		}
	}, [
		fetchServiceDesk,
		hasFetchedServiceDesk,
		isFetchingServiceDesk,
		state,
		serviceDeskId,
	]);

	useEffect(() => {
		if (!isFetchingServiceDeskRequests && !hasFetchedServiceDeskRequests) {
			fetchServiceDeskRequests(serviceDeskId);
		}
	}, [
		fetchServiceDeskRequests,
		isFetchingServiceDeskRequests,
		hasFetchedServiceDeskRequests,
		serviceDeskId,
	]);

	const serviceDesk = state?.serviceDesk || fetchedServiceDesk;
	const isLoading =
		isFetchingServiceDesk ||
		!state?.serviceDesk ||
		isFetchingServiceDeskRequests ||
		!hasFetchedServiceDeskRequests;

	return isLoading ? (
		<Box
			sx={{
				position: "absolute",
				top: "50vh",
				left: "50vw",
				transform: "translate(-50%, -50%)",
			}}
		>
			<BounceLoader color="#44ABDF" size={80} />
		</Box>
	) : (
		<>
			<PageHeader>{serviceDesk.projectName}</PageHeader>

			<SpHeaderBlack sx={{ pl: 16, width: "100%" }}>
				What can we help you with?
			</SpHeaderBlack>

			{isFetchingServiceDeskRequests || !hasFetchedServiceDeskRequests ? (
				<Box
					sx={{
						position: "absolute",
						top: "50vh",
						left: "50vw",
						transform: "translate(-50%, -50%)",
					}}
				>
					<BounceLoader color="#44ABDF" size={80} />
				</Box>
			) : serviceDeskRequests.length ? (
				<>
					<Grid container spacing={8} sx={{ px: 16, py: 4 }}>
						{serviceDeskRequests.map((r) => (
							<Grid key={r.id} size={{ xs: 4 }} container>
								<Card sx={{ width: "100%" }} variant="outlined">
									<CardContent>
										<Stack spacing={2} direction="row">
											<img src={r.icon._links.iconUrls["48x48"]} alt={r.name} />
											<Link
												to={`/helpcenter/desks/${serviceDesk.id}/${r.id}?n=${selectedNamespace}`}
												state={{ serviceDesk, serviceDeskRequest: r }}
											>
												<SpHeader
													sx={{
														p: 0,
														display: "flex",
														height: "32px",
													}}
												>
													{r.name}
												</SpHeader>
												<Box sx={{ color: "#000000", minHeight: "3em" }}>
													{r.description || ""}
												</Box>
											</Link>
										</Stack>
									</CardContent>
								</Card>
							</Grid>
						))}
					</Grid>
					{serviceDesk.externalLinks.length ? (
						<>
							<Divider sx={{ mx: 16 }} />
							<SpHeaderBlack sx={{ pl: 16, pt: 4, width: "100%" }}>
								Resources:
							</SpHeaderBlack>
							<Stack spacing={2} sx={{ px: 16, pt: 4, pb: 8 }}>
								{serviceDesk.externalLinks.map((s, i) => (
									<Stack key={`${s.link}-${i}`} direction={"row"}>
										<LinkIcon sx={{ mr: 1 }} />
										<Box>
											<a href={s.link} target="_blank" rel="noreferrer">
												{s.title}
											</a>
											<Box>{s.description}</Box>
										</Box>
									</Stack>
								))}
							</Stack>
						</>
					) : null}
				</>
			) : null}
		</>
	);
};
