import ArticleIcon from "@mui/icons-material/Article";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

export default ({ answers, metadata, defaultExpanded = true }) => {
	return (
		<Accordion {...{ defaultExpanded }} sx={{ width: "100%" }} square>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				sx={{ display: "flex" }}
			>
				<ArticleIcon sx={{ mr: 1 }} />
				{`${metadata.name}`}
			</AccordionSummary>
			<AccordionDetails>
				<Stack spacing={4}>
					{answers
						.filter((a) => a.answer)
						.map((a, i) => (
							<TextField
								key={`${a.label}-${i}`}
								label={a.label}
								value={a.answer}
								disabled
								fullWidth
							/>
						))}
				</Stack>
			</AccordionDetails>
		</Accordion>
	);
};
