import { Auth0Provider, withAuthenticationRequired } from "@auth0/auth0-react";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import ErrorBoundry from "./Components/ErrorBoundry";
import RequirePermissionOutlet from "./Components/RequirePermissionOutlet";
import AddUserPage from "./Pages/AddUserPage";
import BaseContainer from "./Pages/BaseContainer";
import DashboardPage from "./Pages/DashboardPage";
import EditMePage from "./Pages/EditMePage";
import EditUserPage from "./Pages/EditUserPage";
import ExplorerPage from "./Pages/ExplorerPage";
import ExportDashboardPage from "./Pages/ExportDashboardPage";
import ExportNewPage from "./Pages/ExportNewPage";
import NotFound from "./Pages/NotFound";
import ServiceDeskDashboardPage from "./Pages/ServiceDeskDashboardPage";
import ServiceDeskMyRequestsPage from "./Pages/ServiceDeskMyRequestsPage";
import ServiceDeskRequestSubmitPage from "./Pages/ServiceDeskRequestSubmitPage";
import ServiceDeskRequestTypesPage from "./Pages/ServiceDeskRequestTypesPage";
import ServiceDeskViewRequestPage from "./Pages/ServiceDeskViewRequestPage";
import UserManagementPage from "./Pages/UserManagementPage";
import DefaultTheme from "./theme";

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
	const navigate = useNavigate();
	const onRedirectCallback = (appState) => {
		navigate(appState?.returnTo || window.location.href);
	};
	return (
		<Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
			{children}
		</Auth0Provider>
	);
};

const ProtectedBaseContainer = withAuthenticationRequired(BaseContainer);

export const App = () => {
	return (
		<ThemeProvider theme={DefaultTheme}>
			<BrowserRouter future={{ v7_startTransition: true }}>
				<Auth0ProviderWithRedirectCallback
					domain={import.meta.env.VITE_AUTH_DOMAIN}
					clientId={import.meta.env.VITE_AUTH_CLIENT_ID}
					authorizationParams={{
						audience: import.meta.env.VITE_AUTH_AUDIENCE,
						redirect_uri: window.location.origin,
					}}
					cacheLocation="localstorage"
					useRefreshTokens={true}
					useRefreshTokensFallback={true}
				>
					<Routes>
						<Route
							element={
								<ErrorBoundry>
									<ProtectedBaseContainer />
								</ErrorBoundry>
							}
						>
							<Route path="/" element={<DashboardPage />} />
							<Route path="/exports" element={<ExportDashboardPage />} />
							<Route path="/newexport" element={<ExportNewPage />} />
							<Route path="/dashboard" element={<DashboardPage />} />
							<Route
								path="/helpcenter"
								element={<ServiceDeskDashboardPage />}
							/>
							<Route
								path="/helpcenter/requests"
								element={<ServiceDeskMyRequestsPage />}
							/>
							<Route
								path="/helpcenter/requests/:jiraKey"
								element={<ServiceDeskViewRequestPage />}
							/>
							<Route
								path="/helpcenter/desks/:serviceDeskId"
								element={<ServiceDeskRequestTypesPage />}
							/>
							<Route
								path="/helpcenter/desks/:serviceDeskId/:requestId"
								element={<ServiceDeskRequestSubmitPage />}
							/>
							<Route path="/dashboard/:slug" element={<DashboardPage />} />
							<Route path="/account" element={<EditMePage />} />
							<Route
								element={
									<RequirePermissionOutlet permission="lookerExplorer:read" />
								}
							>
								<Route path="/explore" element={<ExplorerPage />} />
							</Route>
							<Route
								element={
									<RequirePermissionOutlet permission="users:readWrite" />
								}
							>
								<Route path="/users" element={<UserManagementPage />} />
								<Route path="/adduser" element={<AddUserPage />} />
								<Route path="/edituser/:userId" element={<EditUserPage />} />
							</Route>
							<Route path="/404" element={<NotFound />} />
							<Route path="/*" element={<NotFound />} />
						</Route>
					</Routes>
				</Auth0ProviderWithRedirectCallback>
			</BrowserRouter>
		</ThemeProvider>
	);
};
