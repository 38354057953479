import { get, setWith } from "lodash";

function mapTypeToDataString(type) {
	switch (type) {
		case "da":
			return "date";
		case "tl":
		case "te": // should really trigger email validation
		case "ts":
			return "text";
		case "rt":
			return "adf";
		case "at":
			return "files";
		default:
			console.error(`unknown form type ${type}`);
	}
}
// Try to shoehorn the proform types into the native Jira types
const supportedTypes = {
	ts: "string",
	tl: "string",
	te: "string",
	rt: "richtext",
	at: "attachment",
	da: "date",
};

export default class JiraProFormParser {
	constructor() {
		this.requestFieldsById = {};
		this.requestFields = [];
		this.formElements = [];
	}

	getValue(values, id) {
		return get(values, id);
	}

	setValue(values, id, value) {
		setWith(values, id, value, Object);
	}

	parse(formPayload) {
		if (!formPayload) {
			return this;
		}

		this._questions = formPayload.questions;
		this._parse(formPayload.layout.at(0));

		for (const field of this.requestFields) {
			this.requestFieldsById[field.fieldId] = field;
		}

		return this;
	}

	_parse(element) {
		if (element.content?.length) {
			for (const child of element.content) {
				this._parse(child);
			}
		}

		if (element.type === "paragraph" && element.content.length) {
			this.formElements.push({
				jiraSchema: {
					type: "paragraph",
				},
				fieldId: element.attrs.localId,
				text: element.content.at(0)?.text ?? "",
			});
		}

		if (element?.attrs?.extensionKey === "question") {
			const id = element.attrs.parameters.id;
			const q = this._questions[id];

			if (!q) {
				console.error(`question with id ${id} not found`);
				return;
			}

			if (!supportedTypes[q.type]) {
				console.error(`question with type ${q.type} is not supported`);
				return;
			}

			const fieldId =
				q.type === "at" ? `${id}` : `${id}.${mapTypeToDataString(q.type)}`;

			const f = {
				fieldId,
				name: q.label,
				required: !!q.validation?.rq,
				jiraSchema: {
					type: q.type === "at" ? "array" : supportedTypes[q.type],
					system: element?.attrs.extensionType,
					items: q.type === "at" ? "attachment" : undefined,
				},
			};
			this.formElements.push(f);
			this.requestFields.push(f);
		}
	}
}
